var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'wrapper dropdown-wrapper dropdown-border-solo',
    (_vm.iconOnly || _vm.menuItem) ? 'icon-only-view' : '' ]},[(!_vm.iconOnly && !_vm.menuItem)?_c('ProductCellFieldText',{attrs:{"value":_vm.value,"is-expanded-mode":_vm.isExpandedMode,"disabled-edit-mode":_vm.disabledEditMode,"product-hint":_vm.productHint},on:{"emitChange":_vm.emitChange}}):_vm._e(),(_vm.showAddIcon)?_c('app-dialog',{attrs:{"value":_vm.dialog,"width":"1124","persistent":"","content-class":"v-dialog__form","close-on-content-click":false},on:{"update:value":function($event){_vm.dialog=$event}},scopedSlots:_vm._u([(_vm.iconOnly || !_vm.isExpandedMode)?{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('div',{on:{"click":function($event){return _vm.openWindow($event,on)}}},[_c('v-icon',{class:[
            'v-dropdown__custom--add-icon',
            _vm.iconOnly ? '' : 'mr-2 mb-3' ],attrs:{"color":"green"}},[_vm._v(" mdi-plus-circle ")])],1)]}}:(_vm.menuItem)?{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('span',{on:{"click":function($event){return _vm.openWindow($event, on)}}},[_c('v-icon',{attrs:{"size":"16"}},[_vm._v(" mdi-plus-circle ")]),_vm._v("Add Product Reference ")],1)]}}:null],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"pa-0"},[_c('v-card-title',[_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(_vm.columnTitle)+" ")]),_c('span',{staticClass:"sourcery__icon-wrapper black",on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',{attrs:{"color":"white","size":"15"}},[_vm._v(" mdi-close ")])],1)]),_c('v-card-text',{staticClass:"pa-0"},[_c('FieldProductAutocomplete',{staticClass:"mt-4",attrs:{"col":_vm.col,"created-product":_vm.createdProduct,"multiple":_vm.isMultipleField,"value":_vm.value,"dialog":_vm.dialog},on:{"change":_vm.selectProduct},scopedSlots:_vm._u([{key:"appendOuterContent",fn:function(){return [_c('BtnCreateProduct',{attrs:{"loading-btn":_vm.showSpinner},on:{"setStatusDialog":_vm.setStatusDialog}})]},proxy:true}],null,false,2100973387)})],1)],1)],1):_vm._e(),_vm._t("previewDialog",null,{"selectedColumnOrder":_vm.selectedColumnOrder,"selectedRefFields":_vm.selectedRefFields,"selectRefFields":_vm.selectRefFields,"dialog":_vm.dialogForRefFields,"item":_vm.item,"columnTitle":_vm.columnTitle,"manageDialog":_vm.manageDialog}),_c('CollectionsCreateProductDialog',{attrs:{"can-scrape-product":true,"status-dialog":_vm.statusDialogCreateProduct,"is-source-library-id":_vm.isSourceLibraryId,"is-status-dialog-from-state":false,"is-allow-comparison":false,"prop-library-id":_vm.sourceLibraryId},on:{"update:statusDialog":function($event){_vm.statusDialogCreateProduct=$event},"update:status-dialog":function($event){_vm.statusDialogCreateProduct=$event},"savedProduct":_vm.savedProduct}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }