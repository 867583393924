import {
  mapActions, mapMutations, mapState,
} from 'vuex';
import { COMMUNITY_ID } from '@/constants';
export default {
  async created() {
    await this.getActionInfo();
  },
  watch: {
    '$route.params.id': {
      async handler() {
        await this.getActionInfo();
      },
    },
  },
  computed: {
    ...mapState(['activeHeader']),
  },
  beforeDestroy() {
    this.updateActiveElement(null);
    this.resetState();
  },
  methods: {
    ...mapMutations(['updateActiveElement']),
    ...mapMutations('Collections', ['resetState']),
    ...mapActions({
      getInfo: 'getInfo',
    }),
    async getActionInfo() {
      let libraryId = this.$route.params.id;
      if (this.isWorkspacePage) {
        libraryId = COMMUNITY_ID;
      }
      await this.getInfo({
        libraryId,
        route: this.$route,
      });
    },
  },
};
