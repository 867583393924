import {
  mapMutations, mapGetters, mapActions, mapState,
} from 'vuex';
import {
  PRIVATE,
  ROUTE_COMMUNITY_COLLECTION,
  ROUTE_WORKSPACE_PAGE,
  ROUTE_COMMUNITY_COLLECTIONS,
  ROUTE_VERIFIED_PAGE,
} from '@/constants';
import {
  COLLECTION, WORKSPACE, WS_PAGE,
} from '@/constants/cores';
export default {
  computed: {
    ...mapState({
      currentCollectionSharingOption: state => state.Collections.currentCollectionSharingOption,
      activeWorkspaceId: state => state.Workspace.activeWorkspaceId,
      roleInResource: state => state.roleInResource,
    }),
    ...mapGetters({
      getDefaultLibraryId: 'Libraries/getDefaultLibraryId',
      getCorrectLinkWithWS: 'Workspace/getCorrectLinkWithWS',
    }),
    textPrivateSuggestion() {
      return 'This collection became private you can no longer view it. You can ignore the suggestion or keep it and contact the collection owner.';
    },
  },
  methods: {
    ...mapMutations(['updateActiveElement']),
    ...mapActions({
      getCollectionSharingOptions: 'Collections/getCollectionSharingOptions',
      getMyRoleInResource: 'getMyRoleInResource',
    }),
    ...mapActions('Collections', ['getPageQuickViewLink']),
    async verifyCollectionAccess(item) {
      await this.getCollectionSharingOptions(item);
      const { type } = this.currentCollectionSharingOption;
      if (type === PRIVATE) {
        await this.getMyRoleInResource({
          resourceId: item.id,
        });
        const role = this.roleInResource;
        if (!role) {
          const { textPrivateSuggestion: text } = this;
          const res = await this.$openConfirm({
            cancelResponse: true,
            acceptResponse: false,
            cancel: 'Ignore',
            accept: 'Keep',
            text,
          });
          if (res) {
            this.ignoreSuggestion(item);
          }
          return false;
        }
      }
      return true;
    },
    ignoreSuggestion(item) {
      this.$emit('manageActions', {
        action: 'ignoreSuggestion', item,
      });
    },
    async navigateToSharedCollection(id = '') {
      const { params: routeParams, query } = this.$route ?? {
      };
      const { workspaceId } = routeParams ?? {
      };
      const { accessToken } = query ?? {
      };
      const params = {
        collectionId: id,
        workspaceId,
        pageId: workspaceId,
        accessToken,
      };
      const { url } = await this.getPageQuickViewLink(params);
      window.open(url, '_blank').focus();
    },
    navigateNext(e, group, isSharedPage = false) {
      if ([WS_PAGE, 'verifiedWsPages'].includes(group)) {
        this.navigateToWsPage(e);
        return;
      }
      if (isSharedPage) {
        const { id = '' } = e ?? {
        };
        this.navigateToSharedCollection(id);
      } else {
        this.navigateToCollection(e);
      }
    },
    async navigateToWsPage({ id }) {
      this.$router.push({
        name: ROUTE_WORKSPACE_PAGE,
        params: {
          id: 'community',
          pageId: id,
        },
      }).catch(() => {});
    },
    async navigateToCollection(e) {
      const { id, url = null } = e;
      // this case is for suggestions
      if (url) {
        const access = await this.verifyCollectionAccess(e);
        if (!access) return false;
        const { pathname = null, search = null } = new URL(url);
        const path = ((pathname.includes(ROUTE_COMMUNITY_COLLECTION) || pathname.includes(COLLECTION))
          && !pathname.includes(WORKSPACE))
          ? this.getCorrectLinkWithWS(pathname)
          : pathname;
        this.$router.push({
          path: `${path}${search ? search : ''}`,
        });
      } else {
        // this.updateActiveElement(e);
        const libraryId = this.$route.params.id;
        const isDefaultLibraryId = this.getDefaultLibraryId == libraryId;

        const name = [ROUTE_COMMUNITY_COLLECTIONS, ROUTE_WORKSPACE_PAGE, ROUTE_VERIFIED_PAGE].includes(this.$route.name) ? 'community-collection' : 'collection-library';
        const params = {
          id,
          pageId: null,
          ...(this.$route.name !== ROUTE_COMMUNITY_COLLECTIONS && !isDefaultLibraryId && {
            libraryId,
          }),
        };

        // HACK - I have no idea why, but this can fail for the very first collection created.
        this.$router.push({
          name: name,
          params: params,
        }).catch(() => {
          this.$router.push({
            name: name,
            params: params,
          });
        });
      }
    },
    // similar to navigateNext fn
    // this returns the path instead of pushing to router
    async returnNavigateNext(e, group, isSharedPage = false) {
      if (group == WS_PAGE) {
        const { id } = e;
        let route = await this.$router.resolve({
          name: ROUTE_WORKSPACE_PAGE,
          params: {
            id: 'community',
            pageId: id,
          },
        }).href;
        return route;
      }
      if (isSharedPage) {
        const { id = '' } = e ?? {
        };
        return this.returnNavigateToSharedCollection(id);
      } else {
        return this.returnNavigateToCollection(e);
      }
    },
    // similar to navigateToCollection but this returns the route
    async returnNavigateToCollection(e) {
      const { id, url = null } = e;
      if (url) {
        const access = await this.verifyCollectionAccess(e);
        if (!access) return false;
        const { pathname = null, search = null } = new URL(url);
        const path = ((pathname.includes(ROUTE_COMMUNITY_COLLECTION) || pathname.includes(COLLECTION))
          && !pathname.includes(WORKSPACE))
          ? this.getCorrectLinkWithWS(pathname)
          : pathname;
        let route = await this.$router.resolve({
          path: `${path}${search ? search : ''}`,
        });
        const ref = await route.href;
        return ref;
      } else {
        const libraryId = this.$route.params.id;
        const isDefaultLibraryId = this.getDefaultLibraryId == libraryId;
        let route = await this.$router.resolve({
          name: [ROUTE_COMMUNITY_COLLECTIONS, ROUTE_WORKSPACE_PAGE, ROUTE_VERIFIED_PAGE].includes(this.$route.name) ? 'community-collection' : 'collection-library',
          params: {
            id,
            pageId: null,
            ...(this.$route.name !== ROUTE_COMMUNITY_COLLECTIONS && !isDefaultLibraryId && {
              libraryId,
            }),
          },
        });
        const ref = await route.href;
        return ref;
      }
    },
    // copy of navigateToSharedCollection to return the url instead of opening it
    async returnNavigateToSharedCollection(id = '') {
      const { params: routeParams, query } = this.$route ?? {
      };
      const { workspaceId } = routeParams ?? {
      };
      const { accessToken } = query ?? {
      };
      const params = {
        collectionId: id,
        workspaceId,
        pageId: workspaceId,
        accessToken,
      };
      const { url } = await this.getPageQuickViewLink(params);
      return url;
    },
  },
};
