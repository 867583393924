import Vue from 'vue';
import {
  Storage, Auth,
} from 'aws-amplify';
import { ProjectDetailsTableSchedule } from '@/store/ProjectDetailsTableSchedule';
import { Collections } from '@/store/Collections';
import { CollapseSections } from '@/store/CollapseSections';
import { Libraries } from '@/store/Libraries';
import { MyPages } from '@/store/MyPage';
import { Comments } from '@/store/Comments';
import { UserProfile } from '@/store/UserProfile';
import { Payment } from '@/store/Payment';
import { Procore } from '@/store/Procore';
import { Workspace } from '@/store/Workspace';
import { FeatureFlags } from '@/store/FeatureFlags';
import { ScheduleViews } from '@/store/ScheduleViews';
import { ScheduleRows } from '@/store/ScheduleRows';
import { UndoActions } from '@/store/UndoActions';
import { UserPermissions } from '@/store/UserPermissions';
import { ManageSubscriptions } from '@/store/ManageSubscriptions';
import { ExportSchedule } from '@/store/ExportSchedule';
import { UserRoles } from '@/store/UserRoles';
import { ManageFiles } from '@/store/ManageFiles';
import { ScheduleCompare } from '@/store/ScheduleCompare';
import { ProjectAggregation } from '@/store/ProjectAggregation';
import { ProjectDetails } from '@/store/ProjectDetails';
import { Projects } from '@/store/Projects';
import { SearchProjectCells } from '@/store/SearchProjectCells';
import { ProjectVersions } from '@/store/ProjectVersions';
import { Images } from '@/store/Images/index';
import Vuex from 'vuex';
import API from '@/services/graphql';
import CollectionsApi from '@/services/graphql/collections';
import LibrariesApi from '@/services/graphql/libraries';
import Subscriptions from '@/services/graphql/subscriptions';
import router from '@/router';
import createPersistedState from 'vuex-persistedstate';
import {
  isIesExt, isZipExt,
} from '@/utils/fileExtensions';
import FileExtensions from '@/constants/fileExtensions';
import { cacheControl } from '@/constants/cores';
import PaymentsApi from '@/services/graphql/payments';
import ErrorsText from '@/constants/errors';
import { SUBSCRIPTION_COMPLETED } from '@/constants/notificationMessages';
import { StoredProducts } from '@/store/StoredProducts';
import { fileSave } from '@/utils';
Vue.use(Vuex);
const { origin } = window.location;
const persistedstate = createPersistedState({
  paths: [
    'ExportSchedule.listOfFilesForSubscriptions',
    'ExportSchedule.listOfFilesToNotify',
    // 'ManageFiles.cachedFiles',
  ],
});

const PROJECT_ROUTES = ['projects', 'schedule-library', 'project-library'];
const COLLECTION_ROUTES = ['collections', 'collection-library', 'collection-product-link', 'libraries'];
const PAGES_ROUTES = ['my-page'];
const COMMUNITY_ROUTES = ['community-collections', 'community-collection', 'community-collection-product-link', 'workspace-page', 'shared-collection'];

export default new Vuex.Store({
  plugins: [persistedstate],
  state: {
    accessToken: '',
    activeHeader: null,
    activeHeaderRoute: '',
    avatars: {
    },
    banner: {
      value: false,
      message: '',
    },
    commentsSubscriptions: [],
    domain: window.location.hostname,
    filesForPreview: [],
    filesForPreviewIndex: 0,
    imagePreviewOverlay: false,
    isChangingView: false,
    isLoadingRoute: true,
    isLoadingRowArchiveData: false,
    isLoadingRowData: false,
    isWorkspaceOnlySearch: false,
    isMobile: null,
    infoModal: false,
    loginRelatedPages: [
      'login',
      'sign-up',
      'event-login',
      'event-sign-up',
      'confirm-sign-up',
      'reset-password',
      'confirm-reset-password',
      'complete-new-password',
      'get-file',
      'payment',
      'white-label',
      'white-label-forgot',
      'white-label-reset',
    ],
    mode: origin.includes('stage') || origin.includes('app.gosourcery.com')
      ? 'stage' : 'dev',
    navigationDrawer: false,
    networkError: false,
    notSavedDataFlag: false,
    paramsFromCustomView: null,
    projectId: null,
    progressLinear: {
      show: false,
      title: null,
    },
    projectName: null,
    role: 'viewer',
    roles: [],
    roleInResource: null,
    routeParams: {
    },
    scrollValueInProductDialog: false,
    showCreateProjectModal: false,
    showProjectInfoModal: false,
    showShareModal: false,
    showSpinner: false,
    snackbar: {
      isSnackBarShown: false,
      title: null,
      text: '',
      pdfLink: null,
      timeout: 3000,
    },
    subscribePayment: null,
    subscribePaymentSuccess: null,
    subscriptions: [],
    toggleViewConditions: {
      librariesRelatedPages: true,
    },
    totalSubscription: null,
    updateLibraryOrCollection: null,
    uploadingSpinner: false,
    userInfo: null,
    verifiedSearch: false,
    viewTutorial: false,
  },
  mutations: {
    addCommentsSubscriptions(state, payload) {
      state.commentsSubscriptions = [
        ...state.commentsSubscriptions,
        payload,
      ];
    },
    addTableSubscriptions(state, payload) {
      state.subscriptions = [
        ...state.subscriptions,
        payload,
      ];
    },
    addTotalUnreadCommentsSubscription(state, payload) {
      state.totalSubscription = payload;
    },
    changeAvatars(state, { key, url }) {
      state.avatars[key] = url;
    },
    changeRole(state, role) {
      state.role = role;
    },
    clearCommentsSubscriptions(state) {
      state.commentsSubscriptions = [];
    },
    clearFilesForPreview(state) {
      state.filesForPreview = [];
      state.filesForPreviewIndex = 0;
    },
    clearParamsFromCustomView(state) {
      state.paramsFromCustomView = null;
    },
    clearTableSubscriptions(state) {
      state.subscriptions = [];
    },
    clearUserInfo(state) {
      state.userInfo = null;
    },
    closeBanner(state) {
      state.banner = {
        value: false,
        message: '',
      };
    },
    closeProgressLinear(state) {
      state.progressLinear = {
        title: null,
        show: false,
      };
    },
    closeSnackBar(state) {
      state.snackbar = {
        ...state.snackbar,
        dataTest: null,
        isSnackBarShown: false,
        timeout: 3000,
        title: null,
        text: '',
      };
    },
    openSnackBar(state, {
      title,
      text,
      pdfLink,
      color,
      timeout = 3000,
      dataTest,
    }) {
      state.snackbar = {
        timeout,
        isSnackBarShown: true,
        title,
        text,
        pdfLink,
        color,
        dataTest,
      };
    },
    openInfoModal(state, payload) {
      state.infoModal = payload;
    },
    setActiveHeaderRoute(state, payload) {
      state.activeHeaderRoute = payload;
    },
    setNavigationDrawer(state, payload) {
      state.navigationDrawer = payload;
    },
    setNotSavedDataFlag(state, payload) {
      state.notSavedDataFlag = payload;
    },
    setScrollValueInProductDialog(state, payload) {
      state.scrollValueInProductDialog = payload;
    },
    setFilesForPreview(state, payload) {
      state.filesForPreview = payload;
    },
    setFilesForPreviewIndex(state, payload) {
      state.filesForPreviewIndex = payload;
    },
    setProgressLinear(state, payload) {
      state.progressLinear = {
        ...payload,
        show: true,
      };
    },
    setIsMobile(state, payload) {
      state.isMobile = payload;
    },
    setBanner(state, payload) {
      state.banner = payload;
    },
    setParamsFromCustomView(state, payload) {
      state.paramsFromCustomView = payload;
    },
    setAccessToken(state, payload) {
      state.accessToken = payload;
    },
    setIsLoadingRowArchiveData(state, payload) {
      state.isLoadingRowArchiveData = payload;
    },
    setIsChangingView(state, payload) {
      state.isChangingView = payload;
    },
    setIsLoadingRowData(state, payload) {
      state.isLoadingRowData = payload;
    },
    setNetworkError(state, payload) {
      state.networkError = payload;
    },
    setViewMode(state, payload) {
      state.toggleViewConditions = payload;
    },
    setImagePreviewOverlay(state, payload) {
      state.imagePreviewOverlay = payload;
    },
    setUserInfo(state, payload) {
      state.userInfo = {
        ...state.userInfo,
        ...payload,
      };
    },
    setVerifiedSearch(state, payload) {
      state.verifiedSearch = payload;
    },
    spinner(state, show) {
      if (!show && state.uploadingSpinner) {
        state.uploadingSpinner = false;
      }
      state.showSpinner = show;
    },
    setUploadingSpinner(state, payload) {
      state.uploadingSpinner = payload;
    },
    showShareModalAction(state, item) {
      if (item) {
        const { id, projectName } = item;
        state.projectId = id;
        state.projectName = projectName;
      }
      state.showShareModal = !state.showShareModal;
    },
    showProjectInfoModalAction(state, { id, role }) {
      state.projectId = id;
      if (role) {
        state.role = role;
      }
      state.showProjectInfoModal = !state.showProjectInfoModal;
    },
    showModalAction(state, criteria) {
      state[criteria] = !state[criteria];
    },
    subscribePaymentEvent(state, payload) {
      state.subscribePayment = payload;
    },
    subscribePaymentEventSuccess(state, payload) {
      state.subscribePaymentSuccess = payload;
    },
    setRoleInResource(state, payload) {
      state.roleInResource = payload;
    },
    setArrRoles(state, payload) {
      state.roles = payload;
    },
    setUpdatedLibraryOrCollection(state, payload) {
      state.updateLibraryOrCollection = payload;
    },
    setIsLoadingRoute(state, payload) {
      state.isLoadingRoute = payload;
    },
    setViewTutorial(state, payload) {
      state.viewTutorial = payload;
    },
    toggleIsWorkspaceOnlySearch(state, payload) {
      state.isWorkspaceOnlySearch = payload;
    },
    updateActiveElement(state, payload) {
      state.activeHeader = payload;
    },
  },
  actions: {
    computeActiveHeaderLink({ commit }, { name: routeName }) {
      if (PROJECT_ROUTES.includes(routeName)) commit('setActiveHeaderRoute', 'projects');
      else if (COLLECTION_ROUTES.includes(routeName)) commit('setActiveHeaderRoute', 'collections');
      else if (PAGES_ROUTES.includes(routeName)) commit('setActiveHeaderRoute', 'my-page');
      else if (COMMUNITY_ROUTES.includes(routeName)) commit('setActiveHeaderRoute', 'community-collections');
    },
    async getMyRoleInResource({ commit, dispatch, rootState }, {
      showSpinner = true,
      resourceType = 'collection',
      resourceId = null,
      workspaceId: wId = null,
    } = {
    }) {
      const { activeWorkspaceId } = rootState.Workspace;
      const workspaceId = wId || activeWorkspaceId;
      try {
        if (showSpinner) {
          commit('spinner', true);
        }
        const { data } = await API.getMyRoleInResource({
          workspaceId,
          resourceType,
          resourceId,
        });
        commit('setRoleInResource', data.response);
      } catch (err) {
        commit('setRoleInResource', null);
        dispatch('handleError', err);
      } finally {
        if (showSpinner) {
          commit('spinner', false);
        }
      }
    },
    async getCustomView({ commit }, viewId) {
      try {
        const res = await PaymentsApi.getViewParams();
        const data = res.data.response.params;
        if (data.some(e => e.name === 'viewId' && e.value === viewId)) {
          commit('setParamsFromCustomView', data);
          const route = data.find(e => e.name === 'redirect')?.value;
          if (route) {
            const routePayment = JSON.stringify({
              name: route,
            });
            sessionStorage.setItem('previousRouter', routePayment);
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    updateActiveListingElement({ commit, state }, item) {
      const {
        name,
        description,
        collections,
        follow,
        publish,
        author,
        location,
        address,
        logo,
        suggested,
      } = item;
      const newActiveHeader = {
        ...state.activeHeader,
        name,
        description,
        collections,
        follow,
        publish,
        author,
        location,
        logo,
        suggested,
        address,
      };
      commit('updateActiveElement', newActiveHeader);
    },
    async getInfo({ commit, dispatch, state, rootGetters }, payload) {
      const {
        collectionId,
        libraryId,
        criteria,
        windowStatus,
        queryType,
        showInCollectionDetails = false,
        accessToken,
        route,
      } = payload;
      try {
        if (!rootGetters['FeatureFlags/useLazyLoading']) commit('spinner', true);
        const workspaceId = state.Workspace.activeWorkspaceId;
        const isCollection = queryType === 'Collection';
        const { data } = isCollection ?
          await CollectionsApi[criteria]({
            collectionId,
            workspaceId,
            accessToken,
          }) :
          await LibrariesApi.getLibraryInfo({
            libraryId,
            workspaceId,
          });
        let isFollowed = false;
        if (isCollection) {
          try {
            const isFollowedData = await CollectionsApi.isCollectionFollowed({
              collectionId,
              workspaceId,
            });
            isFollowed = isFollowedData.data.response.isFollowed;
          } catch (err) {
            console.log('isCollectionFollowed', err);
          }
        }
        if (data.response.kind === 'custom') {
          await dispatch('Collections/getCustomLibraryCollectionsList', {
            libraryId,
            workspaceId,
            route,
          });
        }
        if (windowStatus === 'update') {
          commit('setUpdatedLibraryOrCollection', data.response);
        } else {
          if (showInCollectionDetails && router.currentRoute.name.includes('collections')) return;
          commit('updateActiveElement', {
            ...data.response,
            ...(data?.response?.id === 'community' && {
              kind: 'default',
            }),
            ...isCollection && {
              follow: isFollowed,
            },
          });
        }
      } catch (err) {
        const error = err?.errors[0]?.message;
        if (criteria === 'getLibraryCollectionInfo' && ErrorsText.ARRAY_ERRORS_ACCESS.includes(error)) dispatch('handleError', error + ' ' + ErrorsText.SUGGEST_ACCESS);
        else {
          dispatch('handleError', err);
        }
        console.log('getCollectionInfo err', err);
      } finally {
        commit('spinner', false);
      }
    },
    setViewMode({ commit, state }, payload) {
      const { toggleViewConditions } = state;
      const newToggleViewConditions = {
        ...toggleViewConditions,
        ...payload,
      };
      commit('setViewMode', newToggleViewConditions);
    },
    async declareAttachment({ rootState, rootGetters }, {
      isCollection = true,
      rowId,
      col,
      schedule_id,
      projectId,
      filename,
      file,
      initialMetadata = null,
    }) {
      const identity_id = rootGetters['UserProfile/identityId'];
      let username = rootState.userInfo.sub;
      const { data } = await API.declareUserAttachement();
      const { id } = data.response;
      let metadata = {
        identity_id,
        id,
        username,
        workspace_id: rootState.Workspace.activeWorkspaceId,
        filename,
      };
      if (!isCollection) {
        metadata = {
          ...metadata,
          col,
          row_id: rowId,
          schedule_id,
          projectId,
          tableType: 'schedule',
          filename,
        };
      } else if (initialMetadata) {
        metadata = {
          ...metadata,
          ...initialMetadata,
          filename,
        };
      }
      let contentType = file.type;
      if (col === 'CAD/BIM File') {
        contentType = 'application/cad-bim';
      }
      if (isIesExt(filename)) {
        contentType = FileExtensions.CONTENT_TYPE_IES;
      }
      if (isZipExt(filename)) {
        contentType = FileExtensions.CONTENT_TYPE_ZIP;
      }
      await Storage.put(`${id}/${filename}`, file.file, {
        contentType,
        level: 'protected',
        metadata,
        cacheControl,
      });
      return id;
    },
    handleError({ commit, dispatch }, error) {
      console.log(error);
      try {
        let text;
        const typeOfError = typeof error;
        const isTypeString = typeOfError === 'string';
        const err = isTypeString ? error : error?.errors[0]?.message;
        text = err ? err : 'Something went wrong';
        const LIMIT_OF_PRODUCTS_TITLE = [
          'This collection reached its limit of products.',
          'Collection already reached its max products count',
        ];
        if (LIMIT_OF_PRODUCTS_TITLE.includes(text)) {
          dispatch('Collections/limitOfProductsInCollectionError');
          return;
        }
        if (['Project is locked', 'Project is locked, please try later.'].includes(text)) {
          dispatch('ProjectDetailsTableSchedule/subscribeUnlockProject');
        } else if (text === 'Network Error') {
          commit('setNetworkError', true);
        }
        commit('openSnackBar', {
          text,
        });
      } catch (err) {
        console.log('Something went wrong', err);
      } finally {
        commit('spinner', false);
      }
    },
    async refreshToken({ commit }) {
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const currentSession = await Auth.currentSession();
        cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
          const payload = session?.idToken?.payload;
          if (payload) {
            commit('setUserInfo', session.idToken.payload);
          }
        });
      } catch (e) {
        console.log('Unable to refresh Token', e);
      }
    },
    async subscribePaymentEventSuccess(
      { commit, dispatch, state },
    ) {
      const userId = state.userInfo.sub;
      try {
        const subscription = await Subscriptions.subscribePaymentEventSuccess({
          userId,
        }).subscribe({
          next: async () => {
            await dispatch('refreshToken');
            dispatch('notifySuccessPayment');
          },
        });
        commit('subscribePaymentEventSuccess', subscription);
      } catch (err) {
        console.log('subs err', err);
      }
    },
    notifySuccessPayment({ commit, state }) {
      state.subscribePayment.unsubscribe();
      state.subscribePaymentSuccess.unsubscribe();
      commit('openSnackBar', {
        title: SUBSCRIPTION_COMPLETED,
        dataTest: 'subscription_completed_popup',
      });
    },
    async subscribePaymentEvent(
      { commit, state },
    ) {
      const userId = state.userInfo.sub;
      try {
        const subscription = await Subscriptions.subscribePaymentEvent({
          userId,
        }).subscribe({
          next: () => {
            commit('openSnackBar', {
              title: 'Something went wrong with your payment',
              color: 'red',
            });
          },
        });
        commit('subscribePaymentEvent', subscription);
      } catch (err) {
        console.log('subs err', err);
      }
    },
    setForUpdateLibraryOrCollection({ commit, state }, item) {
      const { name, description, logo } = item;
      const updatdElement = {
        ...state.updateLibraryOrCollection,
        name,
        description,
        logo,
      };
      commit('setUpdatedLibraryOrCollection', updatdElement);
    },
    async getFileFromS3({ dispatch }, {
      key,
      forceUpdate,
      level = 'public',
      identityId,
      fileName,
    }) {
      try {
        let name = '';
        if (!fileName) {
          [, name] = key.split('/');
        } else {
          name = fileName;
        }
        if (forceUpdate) {
          const url = await Storage.get(key, {
            download: true,
            level,
            identityId,
          });
          fileSave({
            data: url.Body,
            type: url.ContentType,
            name,
          });
        } else {
          const url = await Storage.get(key);
          window.location.href = url;
        }
      } catch (err) {
        dispatch('handleError', 'Error with downloading of Subscription');
      }
    },
    async getAvatar({ commit, rootGetters, dispatch }, { key, getAvatars, context }) {
      if (key) {
        try {
          let url = null;
          if (rootGetters['FeatureFlags/useIncreaseCaching']) {
            await dispatch('ManageFiles/parseFile', {
              key,
              config: {
                level: 'public',
                customPrefix: {
                  public: 'public/avatars/',
                },
              },
            });
            url = rootGetters['ManageFiles/getFileByKey'](key);
          } else {
            url = await Storage.get(key,
              {
                customPrefix: {
                  public: 'public/avatars/',
                }, level: 'public',
              });
          }
          if (getAvatars) {
            commit('changeAvatars', {
              key, url,
            });
            context.$forceUpdate();
          }
        } catch (err) {
          console.log('err with get image', err);
        }
      }
    },
    async downloadFileByBlob({ commit }, { type, filename, url } = {
    }) {
      try {
        const data = await fetch(url).then(r => r.blob());
        fileSave({
          data,
          type,
          name: filename,
        });
      } catch (err) {
        console.log(err);
      } finally {
        commit('spinner', false);
      }
    },
    /**
     * @desc Call mutation resetState in VuexModules
     * Every module that is called need to have
     * resetState mutation
     * default is for default module
     * @param commit
     * @param payload
     */
    resetStates({ commit }, payload) {
      payload.forEach(p => {
        const commitName = p == 'default' ? 'resetState' : `${p}/resetState`;
        commit(commitName);
      });
    },
    async disableNewUser({ commit }) {
      const newVal = {
        'custom:newUser': `1`,
      };
      // get current user session and update the value of newUser => 1
      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, newVal);
      // update the values in store as well
      commit('setViewTutorial', false);
      commit('setUserInfo', newVal);
    },
  },
  getters: {
    collaboratorGroup(state) {
      const groups = state.userInfo && state.userInfo['cognito:groups'];
      return groups && groups.includes('DesignPartner');
    },
    getActiveHeaderRoute(state) {
      return state.activeHeaderRoute;
    },
    getIsWorkspaceOnlySearch(state) {
      return state.isWorkspaceOnlySearch;
    },
    getVerifiedSearch(state) {
      return state.verifiedSearch;
    },
    isShowProgressLinear(state) {
      return state.progressLinear.show;
    },
    openedInfoModal(state) {
      return state.infoModal;
    },
    premiumGroup(state, getters, rootState, rootGetters) {
      const isPersonalWorkspace = rootGetters['Workspace/isPersonalWorkspace'];
      const isFreeWorkspace = rootGetters['Workspace/isFreeWorkspace'];
      const groups = state.userInfo && state.userInfo['cognito:groups'];
      if (isPersonalWorkspace || isFreeWorkspace) {
        return groups && groups.includes('Premium');
      }
      const isNotGuestInWorkspace = !rootGetters['Workspace/isActiveUserGuestInWorkspace'];
      const isPremiumWorkspace = rootGetters['Workspace/isPremiumWorkspace'];
      return groups && groups.includes('Premium') || isPremiumWorkspace && isNotGuestInWorkspace;
    },
    toggleViewCondition: (state) => (criteria) => {
      return state.toggleViewConditions[criteria];
    },
    userId(state) {
      return state.userInfo && state.userInfo.sub;
    },
  },
  modules: {
    Payment,
    ProjectDetailsTableSchedule,
    Collections,
    Libraries,
    Comments,
    UserProfile,
    Procore,
    Workspace,
    FeatureFlags,
    ScheduleViews,
    ScheduleRows,
    UndoActions,
    UserPermissions,
    ManageSubscriptions,
    ExportSchedule,
    UserRoles,
    ManageFiles,
    ScheduleCompare,
    ProjectAggregation,
    ProjectDetails,
    CollapseSections,
    Projects,
    SearchProjectCells,
    ProjectVersions,
    StoredProducts,
    MyPages,
    Images,
  },
});
