<template>
  <VerifiedCommunityV2
    :props-data-for-searching="propsDataForSearching"
    v-bind="propsDataForSearching"
    :props-data-to-sections="propsDataToSections"
    :groups-arr-to-rendering="groupsArrToRendering" />
</template>
<script>
import VerifiedCommunityV2 from '@/components/CommunityCollections/CommunityGroupsWithSearchResults';
import SearchCollectionsAndProducts
  from '@/services/graphql/searching/searchCollectionsAndProducts';
import SearchCollections from '@/services/graphql/searching/searchCollections';
import SearchWorkspacePages from '@/services/graphql/searching/searchWorkspacePages';
import SearchProducts from '@/services/graphql/searching/searchProducts';
import {
  COLLECTIONS_CRITERIA,
  SEARCH_COMMUNITY_COLLECTIONS_V3,
  SEARCH_WORKSPACE_PAGES,
  WORKSPACE_PAGES_CRITERIA,
  SEARCH_COMMUNITY_PRODUCTS_V3,
  PRODUCTS_CRITERIA,
} from '@/constants/searching/searchingAsyncV2';
import { ROUTE_VERIFIED_PAGE } from '@/constants';
import { findAndReplace } from '@/utils/replaceHelper';
import {
  mapGetters, mapState, mapMutations,
} from 'vuex';
export default {
  name: 'VerifiedCommunity',
  components: {
    VerifiedCommunityV2,
  },
  computed: {
    ...mapGetters({
      getCollectionsGroups: 'Collections/getAppropriateCollectionsGroupsByCriteria',
    }),
    ...mapState({
      itemsFromCollectionSearch: state => state.Collections.itemsFromCollectionSearch,
    }),
    groupsArrToRendering() {
      return this.getCollectionsGroups({
        routeName: ROUTE_VERIFIED_PAGE,
      });
    },
    propsDataForSearching() {
      const arrNameRequest = [SEARCH_COMMUNITY_COLLECTIONS_V3, SEARCH_COMMUNITY_PRODUCTS_V3, SEARCH_WORKSPACE_PAGES];
      const { propsDataToSections: data } = this;
      return {
        ...data,
        searchPlaceholder: 'Search in Verified Community',
        asyncProductsApiMethod: {
          request: SearchProducts['asyncCommunityProductsV3'],
          nameRequest: SEARCH_COMMUNITY_PRODUCTS_V3,
          initVariables: {
            filters: {
              isVerified: true,
            },
          },
        },
        arrNameRequest,
        orderOfSections: [
          WORKSPACE_PAGES_CRITERIA,
          COLLECTIONS_CRITERIA,
          PRODUCTS_CRITERIA,
        ],
        asyncCollectionsAndProductsApiMethod: {
          ...data['asyncCollectionsAndWsPagesApiMethod'],
          request: SearchCollectionsAndProducts['asyncCommunityCollectionsAndProductsWithPageV3'],
        },
      };
    },
    propsDataToSections() {
      return {
        asyncCollectionsAndWsPagesApiMethod: {
          request: SearchCollectionsAndProducts['asyncCommunityCollectionsWsPages'],
          initVariables: {
            filters: {
              isVerified: true,
            },
            wpFilters: {
              isVerified: true,
            },
            wpSort: {
              createdDate: 'asc',
            },
            collectionSort: {
              rating: 'asc',
            },
          },
        },
        asyncCollectionsApiMethod: {
          request: SearchCollections['asyncCommunityCollectionsV3'],
          nameRequest: SEARCH_COMMUNITY_COLLECTIONS_V3,
          initVariables: {
            filters: {
              isVerified: true,
            },
            sort: {
              rating: 'asc',
            },
          },
        },
        searchWorkspacePages: {
          request: SearchWorkspacePages.search,
          nameRequest: SEARCH_WORKSPACE_PAGES,
          initVariables: {
            wpFilters: {
              isVerified: true,
            },
            sort: {
              createdDate: 'asc',
            },
          },
        },
      };
    },
  },
  created() {
    this.setFilteredSearchGroups();
  },
  methods: {
    ...mapMutations({
      setItems: 'Collections/setItemsFromCollectionSearch',
    }),
    setFilteredSearchGroups() {
      const { itemsFromCollectionSearch: item } = this;
      const filteredArr = findAndReplace({
        item,
        searchBy: {
          criteria: 'wsPages',
        },
        replacedItem: {
          title: 'Verified Pages',
        },
      });
      this.setItems(filteredArr);
    },
  },
};
</script>