import { PERSONAL_WORKSPACE_ID } from '@/constants';
import {
  LIST_OF_COLLECTIONS_V3,
  LIST_OF_COLLECTIONS_V3_1,
} from '@/constants/collectionsList';
import {
  COLLECTIONS_CRITERIA,
  PRODUCTS_CRITERIA,
  WORKSPACE_PAGES_CRITERIA,
} from '@/constants/searching/searchingAsyncV2';
import { subscribeSearchWorkspacePagesResult } from '@/services/graphql/searching/asyncSearchSubscriptions';
import { ASYNC_SEARCH_WORKSPACE_PAGES } from '@/constants/userPermissions';
export const getDefaultState = () => {
  return {
    activeCollection: {
    },
    collectionCommunityListPageOptions: null,
    collectionFollowedListPageOptions: null,
    collectionPrivateListPageOptions: null,
    collectionPrivateListPageOptionsToAutocomplete: [],
    collectionPublishListPageOptions: null,
    collectionPublishListPageOptionsToAutocomplete: [],
    collectionSharedListPageOptions: null,
    collectionSharedListPageOptionsToAutocomplete: [],
    collectionSuggestionsList: [],
    collectionSuggestionsListPageOptions: null,
    collectionsSchema: null,
    collectionsViews: [],
    collectionGroupsForCollectionPage: ['workspace', 'followed', 'published'],
    communityCollections: [],
    currentCollectionInfo: [],
    currentCollectionSharingOption: null,
    embeddedDialog: false,
    followedCollectionsList: [],
    isCancelled: {
      followedCollections: null,
      publishedCollections: null,
      suggestedCollections: null,
      workspaceCollections: null,
    },
    isCollectionInCustomLibrary: false,
    isFetching: {
      // Collection Page
      workspaceCollections: false,
      followedCollections: false,
      publishedCollections: false,
      // Community Page
      collectionSuggestions: false,
      communityCollections: false,
      communityPages: false,
      verifiedWsPages: false,
      // Collection Details
      header: false,
      products: false,
      scrapingProductData: false,
    },
    isLoadingSearchData: false,
    itemsFromCollectionSearch: [
      {
        title: 'Pages',
        criteria: WORKSPACE_PAGES_CRITERIA,
        specificAsyncQueryForRequest: 'searchWorkspacePages',
        subscriptionFunc: subscribeSearchWorkspacePagesResult,
        subscriptionTitle: ASYNC_SEARCH_WORKSPACE_PAGES,
      },
      {
        title: 'Collections',
        criteria: COLLECTIONS_CRITERIA,
        specificAsyncQueryForRequest: 'asyncCollectionsApiMethod',
      },
      {
        title: 'Products',
        criteria: PRODUCTS_CRITERIA,
        specificAsyncQueryForRequest: 'asyncProductsApiMethod',
      },
    ],
    keysToSearchingProducts: null,
    listOfCollectionsToAutocomplete: [
      {
        name: 'Workspace Collections',
        mutationName: 'setPrivateCollectionsListToAutocomplete',
        routeName: 'collections',
        intersect: true,
        list: 'privateCollectionsListToAutocomplete',
        type: 'any',
        group: 'workspace',
        workspaceForRendering: 'custom',
        paginationState: 'collectionPrivateListPageOptionsToAutocomplete',
        paginationMutation: 'setPrivateCollectionListPageOptionsToAutocomplete',
      },
      {
        name: 'My Collections',
        mutationName: 'setPrivateCollectionsListToAutocomplete',
        routeName: 'collections',
        intersect: true,
        list: 'privateCollectionsListToAutocomplete',
        type: 'any',
        group: 'private',
        workspaceForRendering: PERSONAL_WORKSPACE_ID,
        paginationState: 'collectionPrivateListPageOptionsToAutocomplete',
        paginationMutation: 'setPrivateCollectionListPageOptionsToAutocomplete',
      },
      {
        name: 'Shared Collections',
        mutationName: 'setSharedCollectionsListToAutocomplete',
        routeName: 'collections',
        intersect: true,
        list: 'sharedCollectionsListToAutocomplete',
        type: 'any',
        group: 'shared',
        workspaceForRendering: PERSONAL_WORKSPACE_ID,
        paginationState: 'collectionSharedListPageOptionsToAutocomplete',
        paginationMutation: 'setSharedCollectionListPageOptionsToAutocomplete',
      },
      {
        name: 'Published Collections',
        mutationName: 'setPublishedCollectionsListToAutocomplete',
        routeName: 'collections',
        intersect: true,
        list: 'publishedCollectionsListToAutocomplete',
        type: 'any',
        group: 'published',
        paginationState: 'collectionPublishListPageOptionsToAutocomplete',
        paginationMutation: 'setPublishedCollectionListPageOptionsToAutocomplete',
      },
    ],
    listOfCollectionsV3: LIST_OF_COLLECTIONS_V3,
    listOfCollectionsV3_1: LIST_OF_COLLECTIONS_V3_1,
    otherCollectionsList: [],
    parsedObject: null,
    productCollectionName: '', // only for search
    productCollectionLink: '', // only for search
    // Set this when we want to hide product modal (to show another modal),
    // but we don't want to lose the data and have to reload.
    productModalInvisible: false,
    productModalVariant: 'add',
    productModalSpecSheetUrl: '',
    projectSourceLibraryCollections: [], // only for save as new product
    productToUpdate: null,
    privateCollectionsList: [],
    privateCollectionsListToAutocomplete: [],
    publishedCollectionsList: [],
    publishedCollectionsListToAutocomplete: [],
    searchProductsMode: false,
    selectedControlProductsIds: [],
    selectedProductIdInMenu: '',
    sharedCollectionsList: [],
    sharedCollectionsListToAutocomplete: [],
    sharedLink: {
    },
    sharingDialog: false,
    showEditOfSharing: false,
    suggestionErrorSnackbar: {
      open: false,
      data: null,
    },
    updateProduct: false,
    userCollections: [], // only for save as new product
    verifiedWsPages: [],
    wsPages: [],
    wsListPageOptions: null,
    verifiedWsListPageOptions: null,
  };
};
export const state = getDefaultState();