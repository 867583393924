export const getters = {
  getListOfPages: (state) => {
    return state.pageList.filter(page => !!page.page);
  },
  getFormattedListOfPages: (state) => {
    return state.pageList
      .filter(page => !!page.page)
      .map(({ createdDate, lastModified, page, route }) => ({
        createdDate, lastModified, ...page, route,
      }));
  },
  getIdsOfMyPages: (state) => {
    return state.pageList
      .map(page => {
        return page?.id || '';
      });
  },
};
