<template>
  <div>
    <!-- skeleton while searching -->
    <div
      v-if="!activeHeader"
      class="sourcery__container">
      <CollectionsBaseSkeleton :show-search="!isWorkspacePage" />
    </div>

    <!-- empty page -->
    <AppEmptyList
      v-else-if="isEmpty && activeHeader && !isWorkspacePage"
      class="pt-32"
      :from="isLibraryCollections ? 'library-collections' : 'collections'" />

    <!-- main content -->
    <template v-else>
      <!-- search bar -->
      <CollectionsSearch
        v-if="!isWorkspacePage"
        :is-store-product-preload="isStoreProductPreload"
        :custom-search-keyword="isWorkspacePage ? (workspacePage.name || '') : ''"
        v-bind="propsDataForSearching" />

      <div class="sourcery__container d-flex flex-column gap-6">
        <!-- workspace name -->
        <WorkspacePageHeader
          v-if="isWorkspacePage"
          :hide-follow="isSharedPage"
          :workspace-page="workspacePage" />

        <!-- gallery/list view switch -->
        <div
          v-if="!isSharedPage"
          class="d-flex justify-space-between gap-4">
          <div>
            <ShareLink
              v-if="canUseQuickViewLink"
              is-page-link />
          </div>
          <AppSwitcherToListing />
        </div>

        <template v-if="isLoadingRowData">
          <ListingSkeleton
            v-if="isListingView"
            :items="4" />
          <v-card
            v-else
            class="py-6 pb-15 px-4 mb-10">
            <ScheduleSkeleton
              :header-columns="4"
              :body-rows="4" />
          </v-card>
        </template>
        <template v-else>
          <component
            :is="isListingView ? 'CollectionListing' : 'CollectionsTable'"
            :collections-list="collectionList(selectedCollectionGroup)"
            :group="selectedCollectionGroup.group"
            :hide-column-members="hideColumnMembers"
            :is-loading="isFetchingForGroup(selectedCollectionGroup.group) && (selectedCollectionGroup.expanded || selectedCollectionGroup.expanded === undefined)"
            :is-shared-page="isSharedPage"
            :is-community-collections="isCommunityCollections"
            :show-collapsed-items="showCollapsedItems(selectedCollectionGroup)"
            :show-counter="selectedCollectionGroup.showCounter"
            @click-action-menu="onClickActionMenu({
              ...$event,
            })"
            @onIntersect="onIntersect({
              ...$event,
            })" />
        </template>
      </div>
    </template>

    <slot
      name="createButtonActions"
      v-bind="{ searchProductsMode }" />

    <!-- sharing dialog -->
    <ShareAdvancedDialog
      :start-subscribe="true"
      :disable-actions="disableActionsSharing"
      :disabled="!(disableActionsSharing && !activeCollection.follow)"
      :invite-to-text-type="COLLECTION"
      :item="activeCollection"
      :item-group="activeCollectionGroup"
      @manageSharedUser="
        manageSharedUser({
          variables: {
            ...$event.variables,
            collectionId: activeCollection.id,
          },
          criteria: $event.criteria,
        })
      " />

    <!-- 3 dots menu -->
    <Actions
      v-bind="actionsProps"
      :show-activator="false"
      :item.sync="selectedItem"
      :position-x="positionX"
      :position-y="positionY"
      :is-community-collections="isCommunityCollections"
      :value.sync="showActionsCard"
      @manageActions="manageActions" />
  </div>
</template>
<script>
import {
  mapState, mapActions, mapMutations, mapGetters,
} from 'vuex';

// components
import Actions from '@/components/Collections/CollectionsActions';
import AppEmptyList from '@/components/App/AppEmptyList';
import AppSwitcherToListing from '@/components/App/AppListingElements/AppSwitcherToListing';
import CollectionsBaseSkeleton from '@/components/Collections/CollectionsBaseSkeleton';
import CollectionListing from '@/components/Listing/Collections';
import CollectionsSearch from '@/components/Collections/CollectionsSearch';
import CollectionsTable from '@/components/Collections/CollectionsTable';
import ListingSkeleton from '@/components/App/AppSkeleton/ListingSkeleton';
import ScheduleSkeleton from '@/components/App/AppSkeleton/ScheduleSkeleton';
import ShareAdvancedDialog from '@/components/CollectionsLibrarysRelocate/ShareAdvancedDialog';
import ShareLink from '@/components/CollectionDetails/CollectionShareLink';
import WorkspacePageHeader from '@/components/Workspace/WorkspacePage/WorkspacePageHeader';

// mixins
import AppActionDotsMenu from '@/mixins/AppActionDotsMenu';
import LoadingSpinnerReset from '@/mixins/LoadingSpinnerReset';
import ManageCollectionsActions from '@/mixins/ManageCollectionsActions';
import PropsUtils from '@/mixins/PropsUtils';
import ToggleViewCondition from '@/mixins/ToggleViewCondition';

// utils
import {
  hasCreatorAccess, isOwner, hasCreatorAdminAccess,
} from '@/utils';

// constants
import {
  COMMUNITY_ID, ROUTE_COMMUNITY_COLLECTION,
} from '@/constants';
import { COLLECTION } from '@/constants/cores';
import { COMMENT_RESOURCE_TYPE } from '@/constants/comments';
import {
  NUMBER_OF_COLLAPSED_COLLECTION_IN_ROW,
  COLLECTIONS_WORKSPACE_GROUP,
  COLLECTIONS_PUBLISHED_GROUP,
  COLLECTIONS_FOLLOWED_GROUP,
  COMMUNITY_COLLECTIONS_GROUP,
} from '@/constants/collectionsList';
import {
  COLLAPSE_REORDER_SUBSCRIPTION, COLLECTION_ACCESS,
} from '@/constants/userPermissions';

// services
import SortingApi from '@/services/graphql/sorting';
export default {
  name: 'Collections',
  components: {
    Actions,
    AppEmptyList,
    AppSwitcherToListing,
    CollectionsBaseSkeleton,
    CollectionsTable,
    CollectionListing,
    CollectionsSearch,
    ListingSkeleton,
    ScheduleSkeleton,
    ShareAdvancedDialog,
    ShareLink,
    WorkspacePageHeader,
  },
  mixins: [
    AppActionDotsMenu,
    LoadingSpinnerReset,
    ManageCollectionsActions,
    PropsUtils,
    ToggleViewCondition,
  ],
  props: {
    canUseQuickViewLink: {
      type: Boolean,
      default: false,
    },
    expandedCollections: {
      type: Object,
      default: () => {
      },
    },
    filteredListOfCollections: {
      type: Array,
      default: () => [],
    },
    hideColumnMembers: {
      type: Boolean,
      default: false,
    },
    isLibraryCollections: {
      type: Boolean,
      default: false,
    },
    isCommunityCollections: {
      type: Boolean,
      default: false,
    },
    isWorkspacePage: {
      type: Boolean,
      default: false,
    },
    isWorkspaceEditPage: {
      type: Boolean,
      default: false,
    },
    isSharedPage: {
      type: Boolean,
      default: false,
    },
    isStoreProductPreload: {
      type: Boolean,
      default: false,
    },
    propsDataForSearching: {
      type: Object,
      default: () => {
      },
    },
    sectionsOrder: {
      type: Array,
      default: () => [],
    },
    useSectionSettings: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      COMMUNITY_ID,
      COLLECTION,
      activeCollectionGroup: null,
      actionsProps: {
      },
    };
  },
  computed: {
    ...mapState(['isMobile', 'activeHeader', 'isLoadingRowData']),
    ...mapState('Collections', ['activeCollection', 'searchProductsMode']),
    ...mapState('Libraries', ['librariesList']),
    ...mapState('Workspace', ['activeWorkspaceId']),
    ...mapGetters('Collections', ['getAppropriateCollectionsGroups']),
    ...mapGetters('FeatureFlags', [
      'getCollectionsLimit_100',
      'mobileViewOptimizationsForTradeShow',
      'useMakePageFollowable',
      'useSkeleton',
      'useLazyLoading',
    ]),
    ...mapGetters('UserProfile', ['isSortedCollectionByLastAccessed']),
    ...mapGetters('UserRoles', [
      'canCreateCollectionInCustomLibrary',
      'canCreateCollectionInDefaultLibrary',
      'findUserRoleInLibrary',
    ]),
    ...mapGetters('Workspace', ['findWorkspacePageByPageId']),
    accessToken() {
      return this.$route?.query?.accessToken;
    },
    disableActionsSharing() {
      return ![ROUTE_COMMUNITY_COLLECTION, 'community-collections'].includes(
        this.$route.name
      );
    },
    selectedCollectionGroup() {
      return this.filteredListOfCollections[0];
    },
    getCreateCollectionRule() {
      if (this.isCustomLibrary) {
        return this.canCreateCollectionInCustomLibrary;
      }
      return this.canCreateCollectionInDefaultLibrary;
    },
    getLibraryById() {
      const { paramLibraryId: libraryId } = this;
      return this.$store.getters['Libraries/getLibraryById'](this.activeHeader?.libraryId || libraryId);
    },
    getLibraryName() {
      return this.getLibraryById.name;
    },
    getUserLibraryRole() {
      if (!this.activeHeader) return '';
      return this.findUserRoleInLibrary({
        ...this.activeHeader,
        checkIsPublishCollection: false,
      });
    },
    isAllowedCreateCollection() {
      const { mobileViewOptimizationsForTradeShow: flag } = this;
      if (!flag) return false;
      const { allowed = false } = this.getCreateCollectionRule(this.getUserLibraryRole) || {
      };
      return allowed;
    },
    isCustomLibrary() {
      return this.activeHeader?.kind == 'custom';
    },
    isEmpty() {
      if (this.isFetchingForAny) return false;

      for (const collList of this.filteredListOfCollections) {
        const listName = collList.list;
        const any = this.$store.state.Collections[listName].some(item => item && item.status !== 'deleted');
        if (any) return false;
      }

      return true;
    },
    isFetchingForAny() {
      if (!this.useLazyLoading) return false;
      const collFetchingState = this.$store.state.Collections.isFetching;

      if (collFetchingState.workspaceCollections
        || collFetchingState.publishedCollections
        || collFetchingState.followedCollections
        || collFetchingState.communityCollections) {
        return true;
      }

      const wsFetchingState = this.$store.state.Workspace.isFetching;
      if (wsFetchingState.workspacePages) return true;

      return false;
    },
    isListingView() {
      return this.toggleViewCondition('librariesRelatedPages');
    },
    paramLibraryId() {
      if (this.isWorkspacePage) {
        return COMMUNITY_ID;
      }
      return this.$route.params.id;
    },
    showAddIcon() {
      return !this.searchProductsMode && this.$route.name !== 'community-collections';
    },
    workspacePage() {
      const workspacePage = this.findWorkspacePageByPageId(this.$route.params.pageId);
      if (!workspacePage || !this.isWorkspacePage) {
        return {
          logo: '',
        };
      }
      return workspacePage;
    },
  },
  watch: {
    /**
     * @todo refactor and leave in store
     * state for products mode
     * @param val
     */
    searchProductsMode(val) {
      this.setSearchProductsMode(val);
    },
    activeHeader: function(newAH, oldAH) {
      if (!oldAH && newAH) this.getAppropriateComponentInfo();
    },
  },
  async mounted() {
    this.getAppropriateComponentInfo();
    if (!this.isCommunityCollections) {
      this.getSectionSettings();
      if (this.isSharedPage) {
        return;
      }
      this.setSubscriptionsCollapse();
      this.subscribeTotalUnreadCounter({
        resourceType: COMMENT_RESOURCE_TYPE.COLLECTION,
        libraryId: this.paramLibraryId,
      });
      this.subscribeCollectionsAccess();
    } else if (!this.isSharedPage) {
      this.onWorkspacePageChange();
    }
  },
  beforeDestroy() {
    if (!this.isCommunityCollections) {
      this.closeProgressLinear();
      this.removeSubscriptions([COLLAPSE_REORDER_SUBSCRIPTION,
        `${COMMENT_RESOURCE_TYPE.COLLECTION}_unread_counter`, COLLECTION_ACCESS]);
    } else {
      this.removeSubscriptions(['workspace_page_subscription']);
    }
    this.filteredListOfCollections.map(el => {
      this.setIsCancelled({
        group: el.group,
        value: null,
      });
    });
  },
  methods: {
    ...mapActions({
      handleError: 'handleError',
      removeSubscriptions: 'ManageSubscriptions/removeSubscriptions',
      subscriptionsCollapse: 'CollapseSections/subscriptionsCollapse',
      getWorkspaceSectionSettings: 'CollapseSections/getWorkspaceSectionSettings',
      getCollectionsList: 'Collections/getCollectionsList',
      manageSharedUser: 'Collections/manageSharedUser',
      getLibrariesList: 'Libraries/getLibrariesList',
      subscribeTotalUnreadCounter: 'Comments/subscribeTotalUnreadCounter',
      subscribeCollectionsAccess: 'Collections/subscribeCollectionsAccess',
      getCustomLibraryCollectionsList: 'Collections/getCustomLibraryCollectionsList',
      listWorkspacePages: 'Workspace/listWorkspacePages',
      onWorkspacePageChange: 'Workspace/onWorkspacePageChange',
    }),
    ...mapMutations({
      setUserData: 'UserProfile/setUserData',
      closeProgressLinear: 'closeProgressLinear',
      spinner: 'spinner',
      setIsLoadingRowData: 'setIsLoadingRowData',
      setIsCancelled: 'Collections/setIsCancelled',
      setSearchProductsMode: 'Collections/setSearchProductsMode',
      openInfoModal: 'openInfoModal',
    }),
    hasCreatorAccess,
    isOwner,
    hasCreatorAdminAccess,
    getExpandedAmount({ expandedAmount = 0, rowsToShow = 0 } = {
    }) {
      if (expandedAmount) {
        return expandedAmount;
      }
      if (!rowsToShow) {
        return 0;
      }
      const { width } = this.$vuetify.breakpoint;
      if (width > 1110) {
        return NUMBER_OF_COLLAPSED_COLLECTION_IN_ROW.BIGGEST * rowsToShow;
      }
      if (width > 867) {
        return NUMBER_OF_COLLAPSED_COLLECTION_IN_ROW.MIDDLE * rowsToShow;
      }
      if (width > 500) {
        return NUMBER_OF_COLLAPSED_COLLECTION_IN_ROW.MOBILE_BIG * rowsToShow;
      }
      return NUMBER_OF_COLLAPSED_COLLECTION_IN_ROW.MOBILE_SMALL * rowsToShow;
    },
    async setSubscriptionsCollapse() {
      const { paramLibraryId: libraryId } = this;
      await this.subscriptionsCollapse({
        libraryId,
      });
    },
    async onClickActionMenu({ item, event }) {
      const collection = this.selectedCollectionGroup;
      const { group = null, actionTypes: actionsConditions = [], actions: showActions = false } = collection;
      const { kind = '' } = this.activeHeader;

      this.setMenuItem({
        item,
        event,
      });

      let finalActions = [...actionsConditions];
      if (group === 'wsPage' && !this.useMakePageFollowable) {
        finalActions = [];
      }
      this.actionsProps = {
        group,
        actionsConditions: finalActions,
        showActions,
        kind,
      };
    },
    getSectionSettings() {
      const { paramLibraryId: libraryId } = this;
      this.getWorkspaceSectionSettings({
        libraryId,
      });
    },
    async getAppropriateComponentInfo() {
      if (this.activeHeader || this.isSharedPage) {
        await this.fetchCollections();
      }
      if (!this.librariesList.length && !this.isSharedPage) {
        await this.getLibrariesList();
      }
    },
    filteredCollectionsList(list) {
      return this.$store.state.Collections[list].filter(item => item && item?.status !== 'deleted');
    },
    collectionListLength(collection) {
      return this.collectionList(collection).length;
    },
    showCollapsedItems({ group, showExpanded }) {
      return group
        && showExpanded
        && !this.expandedCollections[group];
    },
    collectionList({ list, group, expandedAmount = 0, rowsToShow = 0, showExpanded = false }) {
      if (this.isListingView) {
        return this.collectionListForListingView({
          list,
        });
      }
      return this.collectionListForTableView({
        list,
        group,
        showExpanded,
        expandedAmount: this.getExpandedAmount({
          expandedAmount,
          rowsToShow,
        }),
      });
    },
    collectionListForListingView({ list }) {
      return this.filteredCollectionsList(list);
    },
    collectionListForTableView({ list, group, expandedAmount = 0, showExpanded }) {
      const filtered = this.filteredCollectionsList(list);
      if (this.showCollapsedItems({
        group,
        showExpanded,
      })) {
        return [...filtered.slice(0, expandedAmount)];
      }
      return filtered;
    },
    collectionOptions(pagination) {
      return this.$store.state.Collections[pagination];
    },
    async fetchCollections(sortedParam) {
      this.setSpinner(true);
      if (this.useSkeleton) this.setIsLoadingRowData(true);

      if (sortedParam) {
        const { data } = await SortingApi.setSortingMode({
          resourceType: sortedParam.resourceType,
          sortingMode: sortedParam.sortingMode,
          ascending: sortedParam.ascending,
        });
        this.setUserData(data.response);
      }
      const { paramLibraryId: libraryId } = this;
      let pageId = null;
      if (this.isWorkspacePage || this.isSharedPage) {
        pageId = this.$route.params?.pageId;
        await this.listWorkspacePages({
          accessToken: this.accessToken,
          pageId: this.$route.params?.pageId,
          workspaceId: this.$route.params?.workspaceId,
        });
      }
      if (this.activeHeader?.kind == 'default' || this.isSharedPage) {
        await Promise.all(this.filteredListOfCollections.map(async el => {
          await this.getCollectionsList({
            libraryId,
            collectionGroup: el.group,
            allowGetAllItems: el?.allowGetAllItems,
            pageId,
            isWsPage: this.isWorkspacePage,
            showSpinner: !this.useLazyLoading,
            filteredListOfCollection: this.filteredListOfCollections,
            ...(this.accessToken && {
              accessToken: this.accessToken,
            }),
            resetCancellation: true,
            comingFromRoute: this.$route.name,
            route: this.$route,
          });
        }));
      } else {
        if (sortedParam) {
          await this.getCustomLibraryCollectionsList({
            libraryId,
            workspaceId: this.activeWorkspaceId,
            route: this.$route,
          });
        }
      }
      if (this.useSkeleton) this.setIsLoadingRowData(false);
      this.setSpinner(false);
    },
    async onIntersect({
      entries,
      isIntersecting,
      expandedAmount = 0,
      rowsToShow = 0,
    }) {
      const {
        list: listName,
        collectionGroup,
        pagination,
      } = this.selectedCollectionGroup;

      await this.$nextTick();
      const nextToken = this.collectionOptions(pagination)?.nextToken;
      const { paramLibraryId: libraryId } = this;
      const list = this.collectionList({
        list: listName, group: collectionGroup, expandedAmount, rowsToShow,
      });
      if (isIntersecting) {
        const collectionId = entries[0].target.id;
        const lastCollectionId = list[list.length - 1].id;
        if (collectionId === lastCollectionId
          && nextToken
        ) {
          this.setSpinner(true);
          await this.getCollectionsList(
            {
              nextTokenForRequest: nextToken,
              collectionGroup,
              libraryId,
              pageId: this.$route.params?.pageId,
              isWsPage: this.isWorkspacePage,
              showSpinner: !this.useLazyLoading,
              ...(this.accessToken && {
                accessToken: this.accessToken,
              }),
              route: this.$route,
            });
          this.setSpinner(false);
        }
      }
    },
    isShowSection(section) {
      if (this.useLazyLoading) {
        if (this.isFetchingForGroup(section?.group)) return true;
      }
      return this.sectionHasCollectionsToShow(section);
    },
    sectionHasCollectionsToShow(section) {
      const lengthCollection = this.isListingView ? this.collectionListLength(section) : true;
      if (this.isSharedPage) {
        return lengthCollection;
      }
      return this.checkTypeToShow(section.type) && !this.searchProductsMode && lengthCollection;
    },
    checkTypeToShow(type) {
      if (this.activeHeader) {
        const trueCondition = [this.activeHeader?.kind, 'any'];
        return trueCondition.includes(type);
      }
    },
    setSpinner(show) {
      if (!this.useSkeleton && !this.useLazyLoading) {
        this.spinner(show);
      }
    },
    isFetchingForGroup(collectionGroup) {
      if (!this.useLazyLoading) return false;
      const fetchingState = this.$store.state.Collections.isFetching;

      switch (collectionGroup) {
      case COLLECTIONS_WORKSPACE_GROUP:
        return fetchingState.workspaceCollections;
      case COLLECTIONS_PUBLISHED_GROUP:
        return fetchingState.publishedCollections;
      case COLLECTIONS_FOLLOWED_GROUP:
        return fetchingState.followedCollections;
      case COMMUNITY_COLLECTIONS_GROUP:
        return fetchingState.communityCollections;
      default:
        return false;
      }
    },
    isCollapseHeaderLoading(section) {
      if (section?.group == COLLECTIONS_WORKSPACE_GROUP) return false;
      if (this.sectionHasCollectionsToShow(section)) return false;

      return this.isFetchingForGroup(section?.group);
    },
  },
};
</script>
