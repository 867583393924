var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ListingImageItem',_vm._g({attrs:{"libraries-list":_vm.collectionsList,"show-collapsed-items":_vm.showCollapsedItems,"collapsed-items-amount":_vm.collapsedItemsAmount,"intersect":_vm.intersect,"is-fetching":_vm.isLoading,"show-counter":_vm.showCounter,"show-unread-comments":_vm.group == _vm.WORKSPACE || _vm.group == _vm.GROUP_SHARED,"counter":{
    prop: 'productsCount',
    type: 'products',
  }},on:{"navigate":function($event){return _vm.navigateNext($event, _vm.group, _vm.isSharedPage)}},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
  var item = ref.item;
return [(!_vm.hideActions && !_vm.isSharedPage)?_c('span',_vm._g({staticClass:"sourcery__icon-wrapper white",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$emit('click-action-menu', {
        item: item,
        event: $event,
      })}}},_vm.$listeners),[_c('v-icon',{attrs:{"color":"black"}},[_vm._v(" mdi-dots-horizontal ")])],1):_vm._e()]}}])},_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }