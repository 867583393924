// types
const XLSX = 'xlsx';
const PDF = 'pdf';
const DOCX = 'docx';
const ZIP = 'zip';
const CSV = 'csv';
// titles
const CUT_SHEET = 'Cut sheet';
const SPECIFICATION_SHEET = 'Specification Sheet';
const CURRENT_VIEW = 'Current view';
const CURRENT_VIEW_VALUE = 'Current view';
const REVIT_EXPORT = 'Revit (csv)';
const CURRENT_VIEW_EXPORT_TYPE = 'xlsxExportView';
const CURRENT_VIEW_SPLIT_EXPORT_TYPE = 'xlsxExportViewSplit';
const CURRENT_VIEW_REVIT_EXPORT_TYPE = 'revitCSV';
const SPECIFICATION_SHEETS = 'Specification Sheets';
const SCHEDULE = 'Schedule';
const BUDGET = 'Budget';
const ELECTRO_VALUE = 'Electrolight';
const ELECTRO_LIGHT_EXPORT = 'Electrolight export';
const HLB_SCHEDULE = 'HLB Schedule';
const MCW_VALUE = 'MCW';
const MCW_EXPORT = 'MCW export';
const MCW_TEMPLATE = 'MCWLuminaireSchedule.xlsx';
const DARKLIGHT_VALUE = 'DarkLight';
const DARKLIGHT_EXPORT = 'DarkLight export';
const DARKLIGHT_TEMPLATE = 'DarkLightSchedule.xlsx';
const HH_ANGUS_EXPORT = 'HH Angus export';
const HH_ANGUS_EXPORT_VALUE = 'scheduleHHAngusXlsx';
const MBI_LUMINAIRES_NEW = 'MBI Luminaires New';
const MBI_LUMINAIRES_OLD = 'MBI Luminaires Old';
const JOURNEY_WORLD = 'Journey Export';
// templates
const TEMPLATE = 'Template.docx';
const TEMPLATE_1 = 'Template1.docx';
const TEMPLATE_1_V2 = 'Template1_v2.docx';
const TEMPLATE_ZIP = 'Template.zip';
const TEMPLATE_CUT_SHEET_HLB = 'TemplateCutSheetHLB.xlsx';
const TEMPLATE_ELECTRO_LIGHT_LUMINAIRE_SCHEDULE = 'ElectrolightLuminaireSchedule.xlsx';
const TEMPLATE_HH_ANGUS_EXPORT = 'SOURCERY_SCHEDULE_HH_ANGUS_TEMPLATE.xlsx';
const TEMPLATE_MBI_LUMINAIRES_NEW = 'TemplateMBILuminairesNew.docx';
const AUTO_CREATE_VERSION_ON_EXPORT = 'Your export has started. Would you also like to create a version of this export for future change tracking?';
export {
  XLSX,
  PDF,
  DOCX,
  CSV,
  CUT_SHEET,
  SCHEDULE,
  BUDGET,
  TEMPLATE,
  TEMPLATE_1,
  SPECIFICATION_SHEET,
  ZIP,
  TEMPLATE_ZIP,
  SPECIFICATION_SHEETS,
  HLB_SCHEDULE,
  TEMPLATE_CUT_SHEET_HLB,
  TEMPLATE_MBI_LUMINAIRES_NEW,
  MBI_LUMINAIRES_NEW,
  MBI_LUMINAIRES_OLD,
  AUTO_CREATE_VERSION_ON_EXPORT,
  TEMPLATE_1_V2,
  HH_ANGUS_EXPORT,
  TEMPLATE_HH_ANGUS_EXPORT,
  HH_ANGUS_EXPORT_VALUE,
  CURRENT_VIEW,
  CURRENT_VIEW_VALUE,
  CURRENT_VIEW_EXPORT_TYPE,
  CURRENT_VIEW_SPLIT_EXPORT_TYPE,
  CURRENT_VIEW_REVIT_EXPORT_TYPE,
  ELECTRO_LIGHT_EXPORT,
  ELECTRO_VALUE,
  TEMPLATE_ELECTRO_LIGHT_LUMINAIRE_SCHEDULE,
  MCW_VALUE,
  MCW_EXPORT,
  MCW_TEMPLATE,
  JOURNEY_WORLD,
  REVIT_EXPORT,
  DARKLIGHT_VALUE,
  DARKLIGHT_EXPORT,
  DARKLIGHT_TEMPLATE,
};
