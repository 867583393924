import {
  hasViewerAccess, isFollowerV2,
} from '@/utils';

import {
  COLLECTION, WORKSPACE,
} from '@/constants/cores';

import {
  ROUTE_COMMUNITY_COLLECTION,
  ROUTE_WORKSPACE_PAGE,
  ROUTE_COMMUNITY_COLLECTIONS,
  ROUTE_VERIFIED_PAGE,
} from '@/constants';

import router from '@/router';

const beforeQuickLinkEnterV2 = async (to, from, next, store) => {
  const id = to.params.id;
  await store.dispatch('Collections/getCollectionSharingOptions', {
    id,
  });
  const { currentCollectionSharingOption } = store.state.Collections;
  await store.dispatch('Collections/getRolesInCurrentCollection', {
    resourceId: id,
    workspaceId: store.state.Workspace.activeWorkspaceId,
  });
  const { roles } = store.state;
  if (!hasViewerAccess(roles) || isFollowerV2(roles)) {
    if (currentCollectionSharingOption.type == 'unlistedPublish') {
      store.commit('setAccessToken', currentCollectionSharingOption.accessToken);
      next();
      return;
    }
    if (currentCollectionSharingOption.type == 'publish') {
      next({
        name: 'community-collection-product-link',
        params: to.params,
      });
      return;
    }
  }
  next();
};

// for workspace pages on community page
const generateCommunityWorkspaceLink = (pages) => {
  return pages.map((page) => {
    const { id } = page;
    let route = router.resolve({
      name: ROUTE_WORKSPACE_PAGE,
      params: {
        id: 'community',
        pageId: id,
      },
    }).href;

    return {
      ...page,
      route,
    };
  });
};

// for followed collections, published collections, collection suggestions on community page
const generateCollectionLinks = (collections = [], getDefaultLibraryId, routeObj) => {
  const libraryId = routeObj.params.id;
  const isDefaultLibraryId = getDefaultLibraryId == libraryId;

  return collections.map((collection) => {
    const { id, url = null } = collection;
    let route;

    if (url) {
      // TODO: implement access check when the user enters the collection
      // const access = await this.verifyCollectionAccess(e);
      // if (!access) return false;
      const { pathname = null, search = null } = new URL(url);
      const path = ((pathname.includes(ROUTE_COMMUNITY_COLLECTION) || pathname.includes(COLLECTION))
        && !pathname.includes(WORKSPACE))
        ? '/' + WORKSPACE + '/' + getDefaultLibraryId + pathname
        : pathname;

      route = router.resolve({
        path: `${path}${search ? search : ''}`,
      }).href;
    } else {
      route = router.resolve({
        name: [ROUTE_COMMUNITY_COLLECTIONS, ROUTE_WORKSPACE_PAGE, ROUTE_VERIFIED_PAGE].includes(routeObj.name) ? 'community-collection' : 'collection-library',
        params: {
          id,
          pageId: null,
          ...(routeObj.name !== ROUTE_COMMUNITY_COLLECTIONS && !isDefaultLibraryId && {
            libraryId,
          }),
        },
      }).href;
    }

    return {
      ...collection,
      route,
    };
  });
};

export {
  beforeQuickLinkEnterV2,
  generateCommunityWorkspaceLink,
  generateCollectionLinks,
};
