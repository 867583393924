import { mapGetters } from 'vuex';
import productHeaders from '@/constants/productHeaders';

export default {
  data() {
    return {
      editMode: false,
    };
  },
  computed: {
    ...mapGetters({
      defaultTableHeaders: 'ProjectDetailsTableSchedule/defaultTableHeaders',
    }),
    productRefHeader() {
      let pHeader = this.defaultTableHeaders.filter(header => header.value === productHeaders.PRODUCT_REFERENCE);
      return pHeader[0];
    },
    productRefInfo() {
      return this.item ? this.item[productHeaders.PRODUCT_REFERENCE] : {
      };
    },
  },
  methods: {
    changeRef(ev) {
      let val = {
        ev,
        col: productHeaders.PRODUCT_REFERENCE,
        columnName: this.productRefHeader.column.name,
        columnType: this.productRefHeader.column.type,
        oldVal: this.value,
        rowId: this.rowId,
      };
      this.$emit('updateCell', val);
    },
  },
};