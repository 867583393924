
<template>
  <ListingImageItem
    :libraries-list="collectionsList"
    :show-collapsed-items="showCollapsedItems"
    :collapsed-items-amount="collapsedItemsAmount"
    :intersect="intersect"
    :is-fetching="isLoading"
    :show-counter="showCounter"
    :show-unread-comments="group == WORKSPACE || group == GROUP_SHARED"
    :counter="{
      prop: 'productsCount',
      type: 'products',
    }"
    v-on="$listeners"
    @navigate="navigateNext($event, group, isSharedPage)">
    <template #actions="{ item }">
      <span
        v-if="!hideActions && !isSharedPage"
        class="sourcery__icon-wrapper white"
        @click.stop.prevent="$emit('click-action-menu', {
          item,
          event: $event,
        })"
        v-on="$listeners">
        <v-icon color="black">
          mdi-dots-horizontal
        </v-icon>
      </span>
    </template>
  </ListingImageItem>
</template>
<script>
import { GROUP_SHARED } from '@/constants';
import { WORKSPACE } from '@/constants/cores';

import ListingImageItem from '../ListingImageItem';

import NavigateToCollection from '@/mixins/NavigateToCollection';

export default {
  name: 'CollectionsListing',
  components: {
    ListingImageItem,
  },
  mixins: [NavigateToCollection],
  props: {
    collectionsList: {
      type: Array,
      default: () => [],
    },
    intersect: {
      type: Boolean,
      default: () => false,
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
    group: {
      type: String,
      default: null,
    },
    showCollapsedItems: {
      type: Boolean,
      default: false,
    },
    collapsedItemsAmount: {
      type: Number,
      default: 4,
    },
    isSharedPage: {
      type: Boolean,
      default: false,
    },
    showCounter: {
      type: Boolean,
      default: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      WORKSPACE,
      GROUP_SHARED,
    };
  },
};
</script>
