import { getDefaultState } from './state';
import {
  COLLECTIONS_FOLLOWED_GROUP,
  COLLECTIONS_PUBLISHED_GROUP,
  COLLECTIONS_WORKSPACE_GROUP,
  SUGGESTIONS_GROUP,
} from '@/constants/collectionsList';
export const mutations = {
  setWsPages(state, payload) {
    state.wsPages = payload;
  },
  setVerifiedWsPages(state, payload) {
    state.verifiedWsPages = payload;
  },
  setSharedLink(state, payload) {
    state.sharedLink = payload;
  },
  setWsListPageOptions(state, payload) {
    state.wsListPageOptions = payload;
  },
  setVerifiedWsListPageOptions(state, payload) {
    state.verifiedWsListPageOptions = payload;
  },
  setEmbeddedDialog(state, payload) {
    state.embeddedDialog = payload;
  },
  setSuggestionErrorSnackbar(state, payload) {
    state.suggestionErrorSnackbar = payload;
  },
  setActiveCollection(state, payload) {
    state.activeCollection = payload;
  },
  setSharingDialog(state, payload) {
    state.sharingDialog = payload;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setCollectionsSchema(state, payload) {
    state.collectionsSchema = payload;
  },
  setCollectionsViews(state, payload) {
    state.collectionsViews = payload;
  },
  changeUpdateProductMode(state, payload) {
    state.updateProduct = payload;
  },
  changeProductCollectionName(state, payload) {
    state.productCollectionName = payload;
  },
  changeProductCollectionLink(state, payload) {
    state.productCollectionLink = payload;
  },
  changeProductModalVariant(state, payload) {
    state.productModalVariant = payload;
  },
  setProductModalSpecSheetUrl(state, payload) {
    state.productModalSpecSheetUrl = payload;
  },
  setCollectionSuggestionsList(state, payload) {
    state.collectionSuggestionsList = payload;
  },
  setPrivateCollectionsList(state, payload) {
    state.privateCollectionsList = payload;
  },
  setSharedCollectionsList(state, payload) {
    state.sharedCollectionsList = payload;
  },
  setPublishedCollectionsList(state, payload) {
    state.publishedCollectionsList = payload;
  },
  setPrivateCollectionsListToAutocomplete(state, payload) {
    state.privateCollectionsListToAutocomplete = payload;
  },
  setSearchProductsMode(state, payload) {
    state.searchProductsMode = payload;
  },
  setSharedCollectionsListToAutocomplete(state, payload) {
    state.sharedCollectionsListToAutocomplete = payload;
  },
  setSelectedProductIdInMenu(state, payload) {
    state.selectedProductIdInMenu = payload;
  },
  setPublishedCollectionsListToAutocomplete(state, payload) {
    state.publishedCollectionsListToAutocomplete = payload;
  },
  setPrivateCollectionListPageOptionsToAutocomplete(state, payload) {
    state.collectionPrivateListPageOptionsToAutocomplete = payload;
  },
  setSharedCollectionListPageOptionsToAutocomplete(state, payload) {
    state.collectionSharedListPageOptionsToAutocomplete = payload;
  },
  setPublishedCollectionListPageOptionsToAutocomplete(state, payload) {
    state.collectionPublishListPageOptionsToAutocomplete = payload;
  },
  setFollowedCollectionsList(state, payload) {
    state.followedCollectionsList = payload;
  },
  setOtherCollectionsList(state, payload) {
    state.otherCollectionsList = payload;
  },
  setCollectionSuggestionsListPageOptions(state, payload) {
    state.collectionSuggestionsListPageOptions = payload;
  },
  setPrivateCollectionListPageOptions(state, payload) {
    state.collectionPrivateListPageOptions = payload;
  },
  setSharedCollectionListPageOptions(state, payload) {
    state.collectionSharedListPageOptions = payload;
  },
  setFollowedCollectionListPageOptions(state, payload) {
    state.collectionFollowedListPageOptions = payload;
  },
  setPublishedCollectionListPageOptions(state, payload) {
    state.collectionPublishListPageOptions = payload;
  },
  setCommunityCollectionListPageOptions(state, payload) {
    state.collectionCommunityListPageOptions = payload;
  },
  setCommunityCollections(state, payload) {
    state.communityCollections = payload;
  },
  setCurrentCollectionInfo(state, payload) {
    state.currentCollectionInfo = payload;
  },
  setParsedObject(state, payload) {
    state.parsedObject = payload;
  },
  setProductToUpdate(state, payload) {
    state.productToUpdate = payload;
  },
  setItemsFromCollectionSearch(state, payload) {
    state.itemsFromCollectionSearch = payload;
  },
  setSelectedControlProductsIds(state, payload) {
    state.selectedControlProductsIds = payload;
  },
  clearSelectedControlProductsIds(state) {
    state.selectedControlProductsIds = [];
  },
  setUserCollections(state, payload) {
    state.userCollections = payload;
  },
  setProjectSourceLibraryCollections(state, payload) {
    state.projectSourceLibraryCollections = payload;
  },
  setIsLoadingSearchData(state, payload) {
    state.isLoadingSearchData = payload;
  },
  setShowEditOfSharing(state, payload) {
    state.showEditOfSharing = payload;
  },
  setCurrentCollectionSharingOption(state, payload) {
    state.currentCollectionSharingOption = payload;
  },
  setKeysToSearchingProducts(state, payload) {
    state.keysToSearchingProducts = payload;
  },
  setIsCancelled(state, payload) {
    const { group, value = null } = payload;
    let key;

    switch (group) {
    case COLLECTIONS_FOLLOWED_GROUP:
      key = 'followedCollections';
      break;
    case COLLECTIONS_PUBLISHED_GROUP:
      key = 'publishedCollections';
      break;
    case COLLECTIONS_WORKSPACE_GROUP:
      key = 'workspaceCollections';
      break;
    case SUGGESTIONS_GROUP:
      key = 'suggestedCollections';
      break;
    }

    state.isCancelled = {
      ...state.isCancelled,
      ...{
        [key]: value,
      },
    };
  },
  setIsCollectionInCustomLibrary(state, payload) {
    state.isCollectionInCustomLibrary = payload;
  },
  setIsFetching(state, payload) {
    state.isFetching = {
      ...state.isFetching, ...payload,
    };
  },
  setProductModalInvisible(state, payload) {
    state.productModalInvisible = payload;
  },
};
